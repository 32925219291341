












import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      types: [
        { description: 'description', type: 'article', name: 'Articles' },
        { description: 'description', type: 'artist', name: 'Artists' },
        { description: 'description', type: 'banner', name: 'Banners' },
        { description: 'description', type: 'category', name: 'Categories' },
        { description: 'description', type: 'collection-type', name: 'Collection Types' },
        { description: 'description', type: 'collection', name: 'Collections' },
        { description: 'description', type: 'course', name: 'Courses' },
        { description: 'description', type: 'genre', name: 'Genres' },
        { description: 'form submits', type: 'form', name: 'Forms' },
        { description: 'description', type: 'interests', name: 'Interests' },
        { description: 'description', type: 'lecture', name: 'Lectures' },
        { description: 'description', type: 'lesson', name: 'Lessons' },
        { description: 'description', type: 'library', name: 'Libraries' },
        { description: 'description', type: 'loop', name: 'Loops' },
        { description: 'description', type: 'meta-event', name: 'Meta Events' },
        { description: 'description', type: 'meta', name: 'Meta' },
        { description: 'description', type: 'midi', name: 'Midi' },
        { description: 'description', type: 'pack-type', name: 'Pack Types' },
        { description: 'description', type: 'pack', name: 'Packs' },
        { description: 'description', type: 'page', name: 'Pages' },
        { description: 'description', type: 'permission', name: 'Permissions' },
        { description: 'description', type: 'production', name: 'Production' },
        { description: 'description', type: 'review', name: 'Reviews' },
        { description: 'description', type: 'style', name: 'Styles' },
        { description: 'description', type: 'tag', name: 'Tags' },
        { description: 'description', type: 'tempo', name: 'Tempos' },
        { description: 'description', type: 'tier', name: 'Tiers' },
        { description: 'description', type: 'user', name: 'Users' },
        { description: 'description', type: 'variant', name: 'Variant' },
        { description: 'description', type: 'video', name: 'Videos' },
      ]
    };
  }
});
